import React from "react";
import "./ImageScroller.css";

const ImageScroller = () => {
  const images = [
    "https://cdn.makemybrand.ai/60892693/0.png",
    "https://cdn.makemybrand.ai/2a45d232/0.png",
    "https://cdn.makemybrand.ai/62aab189/0.png",
    "https://cdn.makemybrand.ai/2989f582/0.png",
    "https://cdn.makemybrand.ai/779d1202/0.png",
    "https://cdn.makemybrand.ai/3c9ceb08/0.png",
    "https://cdn.makemybrand.ai/afa16f8a/0.png",
    "https://cdn.makemybrand.ai/523d3575/0.png",
    "https://cdn.makemybrand.ai/26e8598a/0.png",
    "https://cdn.makemybrand.ai/468dfb7d/0.png",
 ];

  return (
    <div className="scroller-container" style={{
        marginTop: "3rem",
        marginBottom: "3rem",
    }}>
      <div className="scroller-track">
        {images.map((src, index) => (
          <img key={index} src={src} alt={`Sample Infographic`} />
        ))}
        {images.map((src, index) => (
          <img key={`dup-${index}`} src={src} alt={"Sample Infographic"} />
        ))}
      </div>
    </div>
  );
};

export default ImageScroller;
