import { Button } from "@mui/material";
import { colors } from "./colors";
import { login } from "./utils";



export function Header({}) {
    return (
        <div style={{
            padding: "3rem 0",
            textAlign: "center",
        }}>
            <h1 style={{
                fontSize: "3rem",
                fontWeight: 700,
                margin: "auto",
            }}>Create Infographics in 2 Minutes</h1>
            <h2 style={{
                color: "gray",
                fontWeight: 400,
                marginTop: "0.5rem",
                marginBottom: "3rem",
            }}>Using Your Content and Brand Colors</h2>
            <div style={{
                display: "flex",
                justifyContent: "center",
                gap: "2rem",
            }}>
                <Button
                    variant="outlined"
                    size="large"

                    style={{
                        padding: "1rem 3rem",
                        fontSize: "1.5rem",
                        textTransform: "none",
                        borderColor: colors.primary,
                        color: colors.primary,
                    }}
                    onClick={login}
                >Generate from Topic</Button>
                <Button
                    variant="contained"
                    size="large"

                    style={{
                        padding: "1rem 3rem",
                        fontSize: "1.5rem",
                        textTransform: "none",
                        backgroundColor: colors.primary,
                    }}
                    onClick={login}
                >Generate from URL</Button>
            </div>
        </div>
    )
}