import React from "react";
import "./ImageScroller.css";
import EmblaCarouselPngs from "../EmblaCarouselPngs";


// const images = [
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/5ed1011f/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/e005d064/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/8a14477c/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/004ce58f/",
//         "pages": 7
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/9328548c/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/145cb219/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/cd503de9/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/cbad61df/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/ebb3af4b/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/dd08b6f6/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/e684ab33/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/00889453/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/f785e607/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/113039b6/",
//         "pages": 7
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/239f6039/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/d6d5421c/",
//         "pages": 7
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/fc87bffc/",
//         "pages": 7
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/3836f07c/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/0114328f/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/47ff470c/",
//         "pages": 7
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/76aaeeb3/",
//         "pages": 6
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/927d4be6/",
//         "pages": 8
//     },
//     {
//         "prefix": "https://cdn.makemybrand.ai//tmp/abc315e2/",
//         "pages": 8
//     }
// ];
const images = [{
    "prefix": "https://cdn.makemybrand.ai/8a14477c/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/2abc8d6b/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/2140f66e/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/145cb219/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/eeb27453/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/2ffbbb3c/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/85eeccd7/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/cd503de9/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/e005d064/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/5ed1011f/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/004ce58f/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/cbad61df/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/9328548c/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/ebb3af4b/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/3206e75b/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/e684ab33/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/1b490426/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/dd08b6f6/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/bc82d5f8/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/00889453/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/461ba204/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/f785e607/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/c55b6494/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/e09513dd/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/bef71e05/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/1a6dcbcb/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/239f6039/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/66d84272/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/fc87bffc/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/47ff470c/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/d6d5421c/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai//tmp/3836f07c/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/05debb55/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/0114328f/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/927d4be6/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/76aaeeb3/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/cf706f4f/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/abc315e2/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/7854866d/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/fe663031/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/113039b6/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/31236aa7/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/f09a51e6/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/84db938f/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/9300a9c0/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/5a68cc9e/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/92434db9/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/99c37c22/",
    "pages": 6
},
{
    "prefix": "https://cdn.makemybrand.ai/ec73922f/",
    "pages": 6
}]

 for (let i = 0; i < images.length; i++) {
    const carousel = images[i];
    carousel.images = [];
    for (let j = 0; j < carousel.pages; j++) {
        carousel.images.push(`${carousel.prefix}${j}.png`);
    }
}

const ImageScrollerOfCarousels = () => {
  return (
    <div className="scroller-container" style={{
        marginTop: "3rem",
        marginBottom: "3rem",
    }}>
      <div className="scroller-track">
        {images.map((carousel, i) => (
        //   <img key={index} src={src} alt={`Sample Infographic`} />
            <EmblaCarouselPngs key={i} images={carousel.images} />
        ))}
        {images.map((carousel, i) => (
        //   <img key={`dup-${index}`} src={src} alt={"Sample Infographic"} />
            <EmblaCarouselPngs key={i} images={carousel.images} />
        ))}
      </div>
    </div>
  );
};

export default ImageScrollerOfCarousels;
