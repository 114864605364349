
import React, { useState } from "react";
import { Card, CardContent, CardHeader, Button, List, ListItem, ListItemText, Switch, FormControlLabel } from "@mui/material";
import { colors } from "./colors";

const plans = [
    {
      name: "Basic",
      monthlyPrice: "$14.99 / month",
      annualPrice: "$9.99 / month",
      discount: "33% Off",
      features: [
        "100 infographics & carousels/month",
        "Access to Basic templates",
        "Unlimited Edits",
        "Unlimited Downloads",
        "Custom Branding",
        "Email Support",
      ],
      monthlyUrl: 'https://store.makemybrand.ai/buy/49278c51-9434-46da-88f7-546674748657?enabled=673112',
      annualUrl: 'https://store.makemybrand.ai/buy/0679fe47-3def-42ca-8af2-6973ee83b74a?enabled=673114'
    },
    {
      name: "Premium",
      monthlyPrice: "$24.99 / month",
      annualPrice: "$15.99 / month",
      discount: "36% Off",
      features: ["Everything in Basic Plan", "Access to Premium templates"],
      monthlyUrl: 'https://store.makemybrand.ai/buy/5c6cafdb-5b40-4627-bad7-991e9d9b3b28?enabled=673113',
      annualUrl: 'https://store.makemybrand.ai/buy/2dfd878c-e1e6-448f-9df3-fb944f456e65?enabled=681485'
    },
  ];
  

const Pricing = ({landingPage}) => {
  const [isAnnual, setIsAnnual] = useState(false);

  return (
    <div>
      {landingPage == "linkedin" && <TAAFT />}
      <div style={{ 
        padding: "2rem", 
        maxWidth: 800, 
        margin: "auto", 
        marginTop: "3rem", 
        marginBottom: "6rem" 
      }}>
      
        <h2 style={{
          textAlign: "center",
          fontSize: "2rem",
          padding: 0,
          margin: 0
        }}>Simple Pricing</h2>

        <h3 style={{
          textAlign: "center",
          marginBottom: "2rem",
          color: "gray",
        }}>No hidden fees, no complex tools</h3>

        

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          {plans.map((plan, index) => (
            <Card
              key={index}
              elevation={3}
              style={{
                borderRadius: "12px",
                textAlign: "center",
                flex: "1",
                marginLeft: index === 1 ? -30 : 0,
                zIndex: index === 0 ? 1 : 0
              }}
            >
              <CardHeader
                title={plan.name}
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  backgroundColor: index === 0 ? colors.primary : "#f5f5f5",
                  color: index === 0 ? "white" : "black",
                  padding: "1rem",
                }}
              />
              <CardContent>
                <div style={{ fontSize: "1.25rem", fontWeight: "bold", marginBottom: "0.5rem" }}>
                  {isAnnual ? plan.annualPrice : plan.monthlyPrice}
                </div>
                {isAnnual && <div style={{ color: "green", fontWeight: "bold" }}>{plan.discount}</div>}
                <List>
                  {plan.features.map((feature, i) => (
                    <ListItem key={i} disableGutters>
                      <ListItemText primary={feature} style={{ textAlign: "center" }} />
                    </ListItem>
                  ))}
                </List>
                <Button
                  variant={index === 0 ? "contained" : "outlined"}
                  style={{
                    marginTop: "1rem",
                    borderRadius: "20px",
                    padding: "0.5rem 2rem",
                    backgroundColor: index === 0 ? colors.primary : "white",
                    borderColor: colors.primary,
                    color: index === 0 ? "white" : colors.primary,
                  }}
                  onClick={() => {
                    window.location.href = 'https://app.makemybrand.ai/login';
                    // const url = isAnnual ? plan.annualUrl : plan.monthlyUrl;
                    // window.location.href = url;
                  }}
                >
                  Start Free Trial
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>

        <div style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1rem",
          marginTop: "2rem"
        }}>
          <FormControlLabel
            control={<Switch checked={isAnnual} onChange={() => setIsAnnual(!isAnnual)} />}
            label="Annual Billing"
            style={{ textAlign: "center", marginBottom: "1rem" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Pricing;

function TAAFT() {
  return (
    <div style={{ display: "flex", gap: "3rem", justifyContent: "center", alignItems: "center" }}>
      <a href="https://theresanaiforthat.com/ai/make-my-brand/?ref=featured&v=3946004" target="_blank" rel="nofollow">
        <img width="300" src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600" alt="Featured on TAAFT" />
      </a>
    </div>
  );
}
