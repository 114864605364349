import React from "react";
import ImageScroller from "../components/ImageScroller";
import { Header } from "../components/Header";
import { Features } from "../components/Features";
import Pricing from "../components/Pricing";
import { Topbar } from "../components/Topbar";

export default function Wordpress() {
	return (
		<React.Fragment>
			<div style={{ backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: "center" }}>
				<Topbar />
				<Header />
				<ImageScroller />
				<Features />
				<Pricing />
				
				{/* <FooterSectionFootersWhitePattern1 /> */}
			</div>
		</React.Fragment>
	);
}
