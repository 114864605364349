export function Footer({landingPage}) {
    return (
        <div style={{
            marginTop: "6rem",
            paddingBottom: "3rem",

            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            justifyContent: "center",
            alignItems: "center",

        }}>
            
            <div style={{
                display: "flex",
                gap: "3rem",
                justifyContent: "center",
                alignItems: "center",
            }}>
                
            </div>
            <div style={{
                display: "flex",
                gap: "3rem",
                justifyContent: "center",
                alignItems: "start",
            }}>
                {landingPage == "linkedin" && (
                    <>
                    <a href='/infographics'>Infographics Creator</a> |                                     
                    </>
                )}
                {landingPage == "infographics" && (
                    <>
                    <a href='/'>LinkedIn & Instagram Carousels Creator</a> |                                     
                    </>
                )}
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    textAlign: "center",
                }}>
                    <strong>Free Tools</strong>
                    <a href='https://app.makemybrand.ai/free-tools/free-linkedin-banner-generator'>LinkedIn Banner Generator</a>
                    <a href='https://app.makemybrand.ai/free-tools/linkedin-text-formatter'>Linkedin Text Formatter</a>
                    <a href='https://app.makemybrand.ai/free-tools/linkedin-font-generator'>Linkedin Font Generator</a>
                    
                    <a href='https://app.makemybrand.ai/free-tools/free-certificate-generator'>Online Certificate Maker</a>
                    {/* <a href='/ready-to-download-infographics'>Free Infographics</a> */}
                </div>   
                |
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                }}>
                    <a href='/terms'>Terms</a>
                    <a href='/privacy'>Privacy</a>
                    <a href='/contact'>Contact</a>
                    <a href='https://makemybrand.instatus.com/' target='_blank'>Status</a>
                </div>
                |
                <span>&copy; 2025 MakeMyBrand</span>
            </div>
            <div style={{
                display: "flex",
                gap: "3rem",
                justifyContent: "center",
                alignItems: "center",
            }}>
                
            </div>
            {landingPage == "linkedin" && (
                <div style={{
                    opacity: 0,
                }}>
                    <span class="white">Featured on: <a href="https://iuu.ai/">iuu AI</a> & <a href="https://sprunked.dev/">Sprunked</a></span>
                </div>
            )}
        </div>
    )
}