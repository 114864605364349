import React, { useState } from 'react';
import './embla.css';
import {Box} from '@mui/material';

import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'

import useEmblaCarousel from 'embla-carousel-react'

const EmblaCarouselPngs = ({images, options, className='', template, selectedTemplate, setSelectedTemplate}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)
  let [interval, setIntervalInt] = useState();
  const [animationEnabled, setAnimationEnabled] = useState(true);

  function disableAnimation() {
    setAnimationEnabled(false);
    setIntervalInt(null);
    clearInterval(interval);
  }

  return (

    <div
      // key={i}
      onClick={() => setSelectedTemplate && setSelectedTemplate(template)}
      sx={{
        border: (selectedTemplate && template && selectedTemplate?.id === template?.id) ? '4px solid purple' : '4px solid transparent',

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        
        overflow: 'hidden',
        cursor: 'pointer',
        textAlign: 'center',
        width: "fit-content"
      }}
      onMouseLeave={() => {
        setAnimationEnabled(true);

        emblaApi.scrollTo(0);
        clearInterval(interval);
        setIntervalInt(null);
      }}
    >
    <section className={"embla embla-pngs " + className}  onMouseOut={(e) => {
    }} onMouseDown={() => {
      disableAnimation();
    }}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {images.map((img, index) => (
            <div className="embla__slide" key={index}>
                <div className="embla__slide__number">
                  {
                    <img src={img} alt={`Image ${index + 1}`} style={{
                      width: 250,
                      height: 250,
                      objectFit: 'contain'
                    }} 
                    onMouseEnter={(e) => {
                      if (!animationEnabled) return;

                      if (interval) return;
                      

                      const currentIndex = emblaApi?.selectedScrollSnap();
                      if (currentIndex == 0) {
                        onNextButtonClick();
                        interval = setInterval(onNextButtonClick, 1000);
                        setIntervalInt(interval);
                      }
                    }}
                    />
                  }
                </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    </div>
  )
}

export default EmblaCarouselPngs
