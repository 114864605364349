import React from "react";
import ImageScroller from "../components/ImageScroller";
import { Header } from "../components/Header";
import { Features } from "../components/Features";
import { Topbar } from "../components/Topbar";
import Pricing from "../components/Pricing";
import { Footer } from "../components/Footer";


const features = [
    {
      title: "Repurpose Your Blog Posts",
      body: "Just paste your blog URL, and our AI transforms it into a polished infographic. Save time while keeping your brand front and center."
    },
    {
      title: "Short on Ideas for Visual Content?",
      body: "Select from ready-made subjects like 'Employee Time Management' and let our AI design an infographic that speaks to your audience."
    },
    {
      title: "No Design Chops?",
      body: "No need to hire expensive designers. With our AI-driven tool, anyone can create brand-specific, high-quality infographics for a fraction of the cost."
    },
    {
      title: "Short on Time for Content Creation?",
      body: "AI-powered automation turns time-consuming design work into a quick, streamlined process, freeing you up for other priorities while still delivering top-quality visuals."
    },
    {
      title: "Content Lacking Visuals?",
      body: "Stand out on social media, emails, and presentations with infographics tailored to your brand. Visually compelling content boosts shares, clicks, and engagement, extending your reach effortlessly."
    },
    {
      title: "Stop Designing from Scratch",
      body: "Access a library of visually appealing templates, professionally crafted for a range of industries and needs."
    }
  ];
export default function Index() {
	return (
		<React.Fragment>
			<div style={{ backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: "center" }}>
				<Topbar />
				<Header />
				<ImageScroller />
				<Features features={features}/>
				<Pricing landingPage={"infographics"}/>
				
				<Footer landingPage={"infographics"}/>
			</div>
		</React.Fragment>
	);
}