import React from "react";
import ImageScroller from "../components/ImageScroller";
import { Header } from "../components/Header";
import { Features } from "../components/Features";
import { Topbar } from "../components/Topbar";
import Pricing from "../components/Pricing";
import { Footer } from "../components/Footer";
import ImageScrollerOfCarousels from "../components/ImageScrollerOfCarousels";
import { LinkedinHeader } from "../components/LinkedinHeader";


const features = [
    {
        "title": "The LinkedIn Success Formula",
        "body": "LinkedIn's algorithm rewards consistency. Posting 20-30 quality pieces per month rapidly increases your reach and engagement. But creating that volume of high-quality content is overwhelming. MakeMyBrand.ai helps you maintain this ambitious posting schedule without burning out. We make daily posting feel effortless and enjoyable rather than exhausting"
    },
    {
        "title": "Why Carousels?",
        "body": "LinkedIn's data shows PDF carousels get 3x the engagement of plain text posts. But creating these PDFs involves wrestling with complex PDF editing tools. Our creator handles it all - generating ready-to-post PDFs in minutes. When you upload them to LinkedIn, they're automatically converted to swipe-able carousels."
    }, {
        "title": "Grow Your LinkedIn Audience",
        "body": "Social Media is crowded, and standing out matters now more than ever. MakeMyBrand ensures your content is remembered by compressing detailed, useful information into bite-sized slides. We help you break through the noise and establish yourself as a trusted thought leader. Our carousel creator helps you create polished slides with useful, actionable content, attractive visuals and call-to-action buttons."
    },
    {
        "title": "Create from your LinkedIn Home Page",
        "body": "Using our Chrome Extension, you can create stunning carousel posts directly from your LinkedIn Home Page with just a few clicks. Choose from dozens of professionally designed templates and customize them to match your personal brand."
    },
    {
        "title": "Save Time with Professional Templates",
        "body": "Our library of expertly crafted templates is constantly growing. Choose from a variety of styles and customize them to match your personal brand."
    },
    {
        "title": "Build Your Brand",
        "body": "We help you break through the noise and establish yourself as a trusted thought leader in your industry. Grow your LinkedIn audience and build a strong personal brand, with a distinct visual identity that makes your content instantly recognizable. Don't let your LinkedIn profile sit on idle. Use it to open doors to speaking engagements, podcast interviews, consulting requests, and partnership offers."
    },
    {
        "title": "Post Carousels on Instagram & Threads",
        "body": "MakeMyBrand.ai also helps you create Instagram and Threads carousels. Simply download the Zip file and upload the images to your Instagram or Threads account."
    },
    {
        "title": "Repurpose Your Blog",
        "body": "Simply enter the URL of your blog post and we'll create a carousel from it. This is a great way to repurpose your blog content and get more engagement on social media, without the hassle of creating each post from scratch."
    }, {
        "title": "Edit Your Images for Free",
        "body": "Our free, intuitive editor allows you to edit the generated Carousels to match your personal brand. You can add your own images, text, and colors to the Carousels, and make them your own."
    }
];
  
export default function LinkedinPage() {
	return (
		<React.Fragment>
			<div style={{ backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: "center" }}>
				<Topbar />
				<LinkedinHeader />
				<ImageScrollerOfCarousels />
				<Features features={features} />
				<Pricing />
				
				<Footer landingPage={"linkedin"}/>
			</div>
		</React.Fragment>
	);
}