import React from "react";
import { Routes, Route } from "react-router-dom";

import IndexPage from "./pages/Index.js";
import _404Page from "./pages/404.js";
import { Terms } from "./components/terms/terms.js";
import { Privacy } from "./components/terms/privacy.js";
import { PrivacyChromeExtension } from "./components/terms/privacy_chrome_extension.js";
import { Contact } from "./components/terms/contact.js";
import Wordpress from "./pages/Wordpress.js";
import "./App.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ReadyToDownloadList } from "./pages/ReadyToDownload.js";
import LinkedinPage from "./pages/LinkedinPage.js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9c27b0", // Purple
    },
    secondary: {
      main: "#7b1fa2", // Darker purple
    },
    background: {
      default: "#f3e5f5", // Light purple background
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Arial', sans-serif",
  },
});
  

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Routes>
				<Route
					path="/"
					element={<LinkedinPage />}
				/>
				<Route
					path="/infographics"
					element={<IndexPage />}
				/>
				<Route
					path="/makemybrand-wordpress-plugin"
					element={<Wordpress />}
				/>
				<Route
					path="/ready-to-download-infographics"
					element={<ReadyToDownloadList />}
				/>
				<Route
					path="/download/:id"
					element={<ReadyToDownloadList />}
				/>
				<Route
					path="/404"
					element={<_404Page />}
				/>
				<Route
					path="/terms"
					element={<Terms />}
				/>
				<Route
					path="/privacy"
					element={<Privacy />}
				/>
				<Route
					path="/privacy-chrome-extension"
					element={<PrivacyChromeExtension />}
				/>
				<Route
					path="/contact"
					element={<Contact />}
				/>
			</Routes>
		</ThemeProvider>
	);
}

export default App;
