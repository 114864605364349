import { colors } from "./colors";
import { Button,  } from "@mui/material";
import { login } from "./utils";

export function Topbar() {
	return (
		<div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.5rem 1rem",
            backgroundColor: "white",
        }}>
				<div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
					<img
						src="/images/logo.svg"
						alt="MakeMyBrand"
						style={{
                            width: "3rem",
                            height: "3rem",
                        }}
					/>
                    <div>
					<a
						style={{

                        }}
						href="/"
					>
						MakeMyBrand
					</a>
                    </div>
				</div>
				<div style={{

                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem"
                }}>
                    <Button
                        variant="outlined"
						onClick={() => {
                            window.open('https://chromewebstore.google.com/detail/linkedin-carousel-generat/epehcmcofkloohohfibbjdghiefhegfg', '_blank');
                        }}
						style={{
                            textTransform: "none",
                            borderColor: colors.primary,
                            color: colors.primary,
                        }}
					>
                        <img src="/images/chrome.webp" alt="Chrome" style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }} />
						Add to Chrome
					</Button>
					<Button
                        variant="outlined"
						onClick={login}
						style={{
                            textTransform: "none",
                            borderColor: colors.primary,
                            color: colors.primary,

                        }}
					>
						Sign Up
					</Button>
				</div>
			
		</div>
	);
}
