import { useEffect, useState } from "react";
import Gallery from "../components/Gallery";
import { Button, CircularProgress } from "@mui/material";


export function ReadyToDownloadList() {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);

    // we're anyway going to download all of these images when we display them
    // so fetch and find heights
    useEffect(() => {
        setLoading(true);
        // fetch data from https://cdn.makemybrand.ai/ready-to-download.json
        fetch("https://cdn.makemybrand.ai/ready-to-download.json").then(res => res.json()).then(data => {
            const promises = [];
            for (let d of data) {
                const img = new Image();
                img.src = d.preview;
                promises.push(new Promise((resolve, reject) => {
                    img.onload = () => {
                        d.height = img.height;
                        d.width = img.width;
                        setLoading(false);
                        resolve();
                    }
                    img.onerror = () => {
                        d.height = 0;
                        d.width = 0;
                        
                        reject();
                    }
                }));
            }
            Promise.all(promises).then(() => {
                setTemplates(data);
            });
        });        

    }, []);

    function download(url) {
        fetch(url+'?dl=1')
            .then(response => response.blob())
            .then(blob => {
                const a = document.createElement('a');
                const objectUrl = URL.createObjectURL(blob);
                a.href = objectUrl;
                a.download = url.split('/').pop();
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
            })
            .catch(console.error);
    }
    

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "start",
            gap: 15,
            padding: 20
            
        }}>
            <div style={{
                textDecoration: "underline",
                color: "blue",
                textAlign: "center",
                marginTop: 20,
                marginBottom: 20,
                width: "100%",
            }}>
                <a href='https://app.makemybrand.ai/login' style={{
                    textDecoration: "underline",
                    color: "blue",
                    textAlign: "center",
                    marginTop: 20,
                    width: "100%",
                }}>Try our 1 Minute Infographics Generator</a>
            </div>
            <Gallery data={templates} render={(item, width) => (
                <div key={item.title} style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }} className="hover-container">
                    <img src={item.preview} alt={item.title} style={{
                        width: 200,
                        cursor: "pointer"
                    }} />
                    <Button onClick={() => download(item.preview)} className="hover" style={{textTransform: "none"}} variant="contained">Download</Button>
                    <p>{cap(item.subject)}</p>
                </div>
            )} />
        </div>
    )
}


function cap(str) {
    return str.split(" ").map(s => s[0].toUpperCase() + s.slice(1)).join(" ") + " Infographic";
}